export const environment = {
  production: false,
  urlBase: 'https://satis.hmg.vtsis.io/api/Gestor',
  urlBaseMessages: 'https://satis.hmg.vtsis.io/api/GestorMsg',
  dashboardUrl:
    'https://satisbrasil.dev.hotmedia.com.br/report/dashboard_satisfacao/?random=',
  reports: {
    reservas:
      'https://satis-gestor.hmg.vtsis.io/report/relatorios_reserva_refeicoes/?unidade_id=',
    satisfacao_analitico:
      'https://satis-gestor.hmg.vtsis.io/report/relatorios_satisfacao_analitico/?unidade_id=',
    satisfacao_consolidado:
      'https://satis-gestor.hmg.vtsis.io/report/relatorios_satisfacao_consolidado/?unidade_id=',
    insatisfacao_guarnicoes:
      'https://satis-gestor.hmg.vtsis.io/report/relatorios_insatisfacao_cardapios/?unidade_id=',
  },
  charts: {
    satisfacao_mensal:
      'https://satis-gestor.hmg.vtsis.io/report/SatisfConsolidado_mensal/?unidade_id=',
    satisfacao_diario:
      'https://satis-gestor.hmg.vtsis.io/report/SatisfConsolidado_diario/?unidade_id=',
    satisfacao_nro_absolutos:
      'https://satis-gestor.hmg.vtsis.io/report/SatisfConsolidado_mensal_nro/?unidade_id=',
    satisfacao_res_especialidade:
      'https://satis-gestor.hmg.vtsis.io/report/chart_SAT_Reservas/?unidade_id=',
    satisfacao_res_satisfacao:
      'https://satis-gestor.hmg.vtsis.io/report/chart_SAT_Satisfacao/?unidade_id=',
    satisfacao_res_insatisfacao:
      'https://satis-gestor.hmg.vtsis.io/report/chart_SAT_Insatisfacao/?unidade_id=',
  },
};
